import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const RipplesFullwidth = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-1.jpg" alt="Bekal Ripples Brand Identity Presentation 1 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-2.jpg" alt="Bekal Ripples Brand Identity Presentation 2 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-3.jpg" alt="Bekal Ripples Brand Identity Presentation 3 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-4.jpg" alt="Bekal Ripples Brand Identity Presentation 4 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-5.jpg" alt="Bekal Ripples Brand Identity Presentation 5 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-6.jpg" alt="Bekal Ripples Brand Identity Presentation 6 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-7.jpg" alt="Bekal Ripples Brand Identity Presentation 7 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-8.jpg" alt="Bekal Ripples Brand Identity Presentation 8 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-9.jpg" alt="Bekal Ripples Brand Identity Presentation 9 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-10.jpg" alt="Bekal Ripples Brand Identity Presentation 10 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bekal-ripples/bekal-ripples-brand-identity-11.jpg" alt="Bekal Ripples Brand Identity Presentation 11 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                                                                                                                                
            </Row>
        </Container>
    </section>
  )
}

export default RipplesFullwidth