import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const RipplesMain = () => {
  return (
    <div className="main-branding">
        <Container>
            <Row>
                <Col xl={6} xs={12} className="branding-main">
                    <p className="top-link-back"> <span><Link to="/branding" >Branding</Link> </span></p>
                    <h1 className="b-header">
                        Bekal Ripples
                    </h1>
                    <p class="pt-3">Navigate through our serene branding for Bekal Ripples. We captured the essence of luxurious houseboat experiences.</p>
                    <Col md={12} className="b-bottom px-0">
                        <p className="sub-title bstitle mb-0">Hospitality & Tourism</p>                
                    </Col>
                </Col>
            </Row>
        </Container>            
    </div>    
  )
}

export default RipplesMain
