import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import RipplesMain from "../../components/branding/bekal-ripples/ripplesMain"
import RipplesFullwidth from "../../components/branding/bekal-ripples/ripplesFullWidth"
import RipplesMoreBranding from "../../components/branding/bekal-ripples/ripplesMoreBranding"

import brandingFeaturedImage from "../../../static/branding/bekal-ripples/bekal-ripples-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/bekal-ripples.scss"



export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Bekal Ripples: Branding Houseboat Adventures | WowMakers"
            description="Navigate through our serene branding for Bekal Ripples. We captured the essence of luxurious houseboat experiences."
        />
    </>
)

const bekalAquaticsBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-bekal-ripples"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <RipplesMain />
            <RipplesFullwidth />
            <RipplesMoreBranding />
        </Layout>
    )
}

export default bekalAquaticsBranding
